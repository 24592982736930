// src/App.js
import React from 'react';
import Home from './components/Home';
import './App.css';

const App = () => {
  const calculateAge = (humanInput, dogInput) => {
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    const humanBirthday = new Date(Date.parse(humanInput) + tzOffset);
    const dogBirthday = new Date(Date.parse(dogInput) + tzOffset);
    const sameAgeDate = new Date(((7 * dogBirthday) - humanBirthday) / 6);
    return sameAgeDate;
  };

  return (
    <div className="App">
      <Home calculateAge={calculateAge} />
    </div>
  );
};

export default App;