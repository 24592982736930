// src/components/Result.js
import React, { useState, useRef } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import html2canvas from 'html2canvas';

const Result = ({ date }) => {
  const [humanName, setHumanName] = useState('You');
  const [dogName, setDogName] = useState('Your Dog 🐶');
  const [uploadedImage, setUploadedImage] = useState(null); // State for uploaded image
  const shareUrl = 'https://doggo.tshows.us'; // Replace with actual URL
  const imageRef = useRef(null);
  const preposition = date < new Date() ? 'were' : 'will be';

  // Format the date to "Month day, year" format
  const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formattedDate = formatDate(date); // Format the date
  const message = `${humanName} and ${dogName} ${preposition} the same age on ${formattedDate}!`;

  // Handle file input change and set uploaded image
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setUploadedImage(imageURL);
    }
  };

  const handleGenerateImage = () => {
    const element = imageRef.current;
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'doggo by tshows.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <div className="result-container">
      <h2>{message}</h2>
      <hr />
      <h1>Share it with the world!</h1>

      {/* Optional Name Inputs */}
      <form className="name-inputs">
        <label>
          Your Name (optional):
          <input
            type="text"
            value={humanName}
            onChange={(e) => setHumanName(e.target.value)}
            placeholder="Your Name"
            autocomplete="given-name"
          />
        </label>
        <label>
          Dog's Name (optional):
          <input
            type="text"
            value={dogName}
            onChange={(e) => setDogName(e.target.value)}
            placeholder="Dog's Name"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="on"
          />
        </label>
      </form>

      {/* Image upload option */}
      <div className="image-upload">
        <label>
          Upload a picture of you and your pup (optional):
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </label>
      </div>

      {/* This div will be captured as the image */}
      <div ref={imageRef} className="dog-themed-image">
        <div className="dog-themed-content">
          <h1>{humanName} and {dogName}</h1>
          <h2>🎉 Same Age on {formattedDate}! 🥳</h2>
          {uploadedImage ? (
            <img src={uploadedImage} alt="Uploaded" className="dog-image" />
          ) : (
            <img src="/doggo-default.webp" alt="dog" className="dog-image" />
          )}
        </div>
      </div>

      <div className="share-buttons">
        <button onClick={handleGenerateImage}>Download image</button>
        <FacebookShareButton url={shareUrl} quote={message}>
          <button>Share on Facebook</button>
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={message}>
          <button>Share on Twitter</button>
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default Result;
