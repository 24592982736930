// src/components/Home.js
import React, { useState } from 'react';
import Animation from './Animation';
import Result from './Result';

const Home = ({ calculateAge }) => {
  const [humanBirthday, setHumanBirthday] = useState('');
  const [dogBirthday, setDogBirthday] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      const sameAgeDate = calculateAge(humanBirthday, dogBirthday);
      setResult(sameAgeDate);
      setLoading(false);
    }, 2000); // Simulating animation duration
  };

  return (
    <div className="home-container">
      <h1>🐶 Doggo/Human Age Calculator 🗓️</h1>
      <p>If dogs age 7 years for every 1 human year, have you ever wondered when you and your dog will be or were the same age?  Find out now!</p>
      <form onSubmit={handleSubmit}>
        <label>
          Your Birthday:
          <input
            type="date"
            value={humanBirthday}
            onChange={(e) => setHumanBirthday(e.target.value)}
            required
          />
        </label>
        <label>
          Dog's Birthday:
          <input
            type="date"
            value={dogBirthday}
            onChange={(e) => setDogBirthday(e.target.value)}
            required
          />
        </label>
        <button type="submit">Calculate!</button>
      </form>
      {loading && (
        <div className="animation-container">
          <Animation />
        </div>
      )}
      {result && <Result date={result} />}
    </div>
  );
};

export default Home;